import { Typography } from "@material-ui/core"
import { GeelyCitiesMap } from "api"
import { Desktop, useIsDesktop } from "common/MediaQueries"
import SingleButtonSelection from "common/SingleButtonSelection"
import React, { Dispatch, SetStateAction } from "react"
import styled, { css } from "styled-components"

const StyledHeaderWrap = styled.div`
  padding: 10px 13px;
  background: transparent;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 20px;
    padding-right: 0;
  }
`
const StyledSubtitle = styled(Typography).attrs({
  variant: "h2",
  component: "h3",
})`
  font-weight: bold;
  margin-bottom: 0;
  font-size: 14px;
`

const StyledButton = styled.label<{ $active: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  padding: 6px 8px;
  background-color: ${(p) =>
    p.$active ? p.theme.colors.midnight : p.theme.colors.white};
  box-shadow: 0 5px 20px 0 ${(p) => p.theme.colors.paleGrey};

  color: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.coolGrey)};
  font-weight: ${(p) => (p.$active ? "bold" : "normal")};

  ${(p) => p.theme.breakpoints.down("sm")} {
    &&:hover {
      background-color: ${(p) => p.theme.colors.midnight};
    }
    :focus {
      background-color: ${(p) => p.theme.colors.midnight};
      color: ${(p) => p.theme.colors.white};
      outline: 1px solid ${(p) => p.theme.colors.midnight};
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 80px;
    border: 2px solid ${(p) => p.theme.colors.paleGrey};
    width: 155px;
    border-radius: 15px;
    font-weight: ${(p) => (p.$active ? "bold" : "normal")};
    background-color: ${(p) =>
      p.$active ? p.theme.colors.paleGreyThree : p.theme.colors.white};
    color: ${(p) =>
      p.$active ? p.theme.colors.midnight : p.theme.colors.steelGrey};
    box-shadow: ${(p) =>
      !p.$active && css`0 5px 20px 0 ${(p) => p.theme.colors.paleGrey}`};
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`
const StyledButtonTextContainer = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
  }
`
const StyledButtonDistance = styled.span`
  font-weight: bold;
  font-size: 1.2em;
  span {
    font-size: 0.6em;
  }
`

type RouteDescriptionProps = {
  activeRoute: GeelyCitiesMap
  setActiveRoute: Dispatch<SetStateAction<GeelyCitiesMap>>
  routes: GeelyCitiesMap[]
}

const RouteDescription = ({
  setActiveRoute,
  routes,
}: RouteDescriptionProps) => {
  const isDesktop = useIsDesktop()
  const handleButtonClick = (route: GeelyCitiesMap) => {
    setActiveRoute(route)
  }

  return (
    <>
      <StyledHeaderWrap>
        <Desktop>
          <StyledSubtitle id="routesSelect">מסלולי נסיעה</StyledSubtitle>
        </Desktop>

        <SingleButtonSelection
          id="routesSelect"
          items={routes.map((route, index) => ({
            value: index,
            name: `${route.cityA.name}:${route.cityB.name}`,
            secondaryName: route.distance.toString(),
          }))}
          onSelect={(code) => handleButtonClick(routes[Number(code)])}
          gap={15}
          itemsPerRow={isDesktop ? "auto" : 3}
          customButton={(radioId, isSelected, item) => {
            const cityA = item.name.split(":")[0]
            const cityB = item.name.split(":")[1]
            const distance = item.secondaryName

            return (
              <>
                <StyledButton htmlFor={radioId} $active={isSelected}>
                  {" "}
                  <StyledButtonTextContainer>
                    <>
                      {cityA} &larr; {cityB}
                    </>
                    <Desktop>
                      <StyledButtonDistance role="button">
                        {distance}
                        <span>&nbsp;ק&quot;מ</span>
                      </StyledButtonDistance>
                    </Desktop>
                  </StyledButtonTextContainer>
                </StyledButton>
              </>
            )
          }}
        />
      </StyledHeaderWrap>
    </>
  )
}

export default RouteDescription
