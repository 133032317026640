import { Box, Typography, TypographyProps } from "@material-ui/core"
import { FC } from "react"
import styled from "styled-components"

const StyledContainer = styled(Box)<{ $isshowroom?: string }>`
  padding: 12px 0;
  ${(p) => p.$isshowroom === "true" && "padding-bottom:20px"}
`

const StyledHeadline = styled(Typography)<
  TypographyProps & { component: string; isshowroom?: string }
>`
  color: ${(p) => p.theme.colors.midGray};
  padding-bottom: ${(p) => (p.isshowroom === "true" ? "16px" : "8px")};
  font-size: ${(p) => (p.isshowroom === "true" ? "40px" : "14px")};
  display: block;
`

type RowWithTitleProps = {
  title: string
  id?: string
  noPrint?: boolean
  headlineComponent?: string
  isshowroom?: boolean
}

const RowWithTitle: FC<RowWithTitleProps> = ({
  children,
  title,
  id,
  noPrint,
  headlineComponent,
  isshowroom,
}) => {
  return (
    <StyledContainer
      $isshowroom={isshowroom && isshowroom?.toString()}
      displayPrint={noPrint ? "none" : null}
    >
      {title && (
        <StyledHeadline
          variant="body1"
          component={headlineComponent ?? "h2"}
          id={id}
          isshowroom={isshowroom && isshowroom?.toString()}
        >
          {title}
        </StyledHeadline>
      )}
      {children}
    </StyledContainer>
  )
}

export default RowWithTitle
