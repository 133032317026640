import { GeelyCarModelResponse, GeelyCitiesMap } from "api"
import BottomButtons from "common/BottomButtons"
import BottomText from "common/BottomText"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import dynamic from "next/dynamic"
import { useCallback, useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import BatteryDescription from "./BatteryDescription"
import RouteDescription from "./RouteDescription"

const Map = dynamic(() => import("./Map"), { ssr: false })

const StyledSectionContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row;
  }
`
const StyledButtonContainer = styled.div<{ $inView: boolean }>`
  ${(p) => p.theme.breakpoints.down("sm")} {
    position: absolute;
    top: 0;
    width: 100%;
    transition: all 300ms ease-in-out;
    z-index: 1;

    ${(p) =>
      !p.$inView
        ? `transform: translateY(-100px); opacity:0;`
        : `transform: translateY(0); opacity: 1;`}
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 100px;
    padding-bottom: 10px;
  }
`
const StyledBottomTextWrap = styled(BottomText)`
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-right: 0px;
  }
`
const StyledBottomTextContainer = styled.div`
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-left: 50px;
  }
`
const StyledBottomBottons = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: -20px;
  }
`

const MapsSection = ({ data }: { data: GeelyCarModelResponse }) => {
  const [activeRoute, setActiveRoute] = useState<GeelyCitiesMap>(
    data.citiesCoordinates[0]
  )
  const [activeBattery, setActiveBattery] = useState(
    data.batteries.length ? data.batteries[0].range : 0
  )
  const [hideRouteDescription, setHideRouteDescription] = useState(false)
  const isDesktop = useIsDesktop()
  const { ref, inView } = useInView({
    threshold: 0.5,
  })
  const { ref: mapRef, inView: mapInView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "500px",
  })

  const multiRef = useCallback(
    (node) => {
      ref(node)
      mapRef(node)
    },
    [mapRef, ref]
  )

  return (
    <StyledSectionContainer ref={multiRef}>
      <StyledButtonContainer $inView={inView}>
        <BatteryDescription data={data} setActiveBattery={setActiveBattery} />
        {(!hideRouteDescription || isDesktop) && (
          <>
            <RouteDescription
              activeRoute={activeRoute}
              setActiveRoute={setActiveRoute}
              routes={data.citiesCoordinates}
            />
          </>
        )}
        <Desktop>
          <StyledBottomBottons>
            <BottomButtons align="start" />
          </StyledBottomBottons>
          <StyledBottomTextContainer>
            <StyledBottomTextWrap
              align="flex-start"
              inputText={data.batteryLegalTerms}
            />
          </StyledBottomTextContainer>
        </Desktop>
      </StyledButtonContainer>
      {mapInView && (
        <Map
          markers={data.chargingStations}
          activeRoute={activeRoute}
          batteryRange={activeBattery}
          cities={data.cities}
          setHideRouteDescription={setHideRouteDescription}
        />
      )}
      <Mobile>
        <StyledBottomTextContainer>
          <StyledBottomTextWrap
            align="center"
            textAlign="center"
            inputText={data.batteryLegalTerms}
          />
        </StyledBottomTextContainer>
      </Mobile>
    </StyledSectionContainer>
  )
}

export default MapsSection
