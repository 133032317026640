import { Typography } from "@material-ui/core"
import { GeelyCarModelBattery } from "api"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import SingleButtonSelection from "common/SingleButtonSelection"
import Image from "next/image"
import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

const StyledButton = styled.label<{ $activeBattery: boolean }>`
  width: 155px;
  height: 45px;
  box-shadow: 0 5px 20px 0 ${(p) => p.theme.colors.paleGrey};
  color: ${(p) =>
    p.$activeBattery ? p.theme.colors.midnight : p.theme.colors.steelGrey};
  ${(p) => p.theme.breakpoints.down("sm")} {
    border: 1px solid;
    width: 100%;
    border-color: ${(p) =>
      p.$activeBattery ? p.theme.colors.midnight : p.theme.colors.white};
  }
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  :focus {
    outline: none;
  }
`
const StyledButtonRange = styled.span`
  font-weight: bold;
  font-size: 1.2em;
  span {
    font-size: 0.6em;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 24px;
    span {
      font-size: 10px;
    }
  }
`
const StyledDesktopButtonContainer = styled.label<{ $activeBattery: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: ${(p) => (p.$activeBattery ? 1 : 0.4)};
`
const StyledBatteryName = styled(Typography)``
const StyledDesktopButton = styled.img`
  position: relative;
`

const StyledDesktopButtonText = styled.div`
  position: absolute;
  inset: 0 0 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 1.75;
`
const StyledImageContainer = styled.div`
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 0px;

  :focus {
    outline: none;
  }
`

type BatteryDescriptionProps = {
  setActiveBattery: Dispatch<SetStateAction<number>>
  batteries: GeelyCarModelBattery[]
  headlineId: string
  noTitle?: boolean
}
const Batteries = ({
  setActiveBattery,
  batteries,
  headlineId,
  noTitle,
}: BatteryDescriptionProps) => {
  const isDesktop = useIsDesktop()
  const handleButtonClick = (range: number) => {
    setActiveBattery(range)
  }

  return (
    <>
      <SingleButtonSelection
        id={headlineId}
        items={batteries.map((battery, index) => ({
          value: index,
          name: battery.range.toString(),
          secondaryName: battery.name,
        }))}
        onSelect={(code) => handleButtonClick(batteries[Number(code)].range)}
        gap={isDesktop ? 15 : 10}
        itemsPerRow={isDesktop ? "auto" : 2}
        customButton={(radioId, isSelected, item) => (
          <>
            <Mobile>
              <StyledButton htmlFor={radioId} $activeBattery={isSelected}>
                סוללה {item.secondaryName} &nbsp;
                <StyledButtonRange>
                  {item.name}
                  <span>&nbsp;ק&quot;מ</span>
                </StyledButtonRange>
              </StyledButton>
            </Mobile>

            <Desktop>
              <StyledDesktopButtonContainer
                $activeBattery={isSelected}
                htmlFor={radioId}
              >
                {!noTitle ? (
                  <StyledBatteryName>{item.secondaryName} </StyledBatteryName>
                ) : (
                  <></>
                )}
                <StyledImageContainer>
                  <StyledDesktopButton src="/path-221.svg" />
                  <StyledDesktopButtonText>
                    {isSelected && (
                      <Image
                        width="14px"
                        height="19px"
                        src="/bolt.svg"
                        alt=""
                      />
                    )}
                    <StyledButtonRange>
                      &nbsp; {item.name}
                      <span>&nbsp;ק&quot;מ</span>
                    </StyledButtonRange>
                  </StyledDesktopButtonText>
                </StyledImageContainer>
              </StyledDesktopButtonContainer>
            </Desktop>
          </>
        )}
      />
    </>
  )
}

export default Batteries
