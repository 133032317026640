import { Typography } from "@material-ui/core"
import { GeelyCarModelResponse } from "api"
import { Desktop } from "common/MediaQueries"
import Batteries from "features/sections/Map/Batteries"
import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

const StyledHeaderWrap = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  padding: 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 0;
  }
`
const StyledHeadline = styled(Typography).attrs({ variant: "h2" })`
  font-size: 26px;
  font-weight: normal;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 38px;
  }
`
const StyledDescription = styled(Typography)`
  font-size: 14px;
`
const StyledSubtitle = styled(Typography).attrs({
  variant: "h2",
  component: "h3",
})`
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 0;
  font-size: 14px;
`

type BatteryDescriptionProps = {
  setActiveBattery: Dispatch<SetStateAction<number>>
  data: GeelyCarModelResponse
}
const BatteryDescription = ({
  setActiveBattery,
  data,
}: BatteryDescriptionProps) => {
  const batteryData = data.batteries
  return (
    <>
      <StyledHeaderWrap>
        <StyledHeadline>{data.batteriesTitles.mainTitle}</StyledHeadline>
        <Desktop>
          <StyledDescription>{data.batteriesTitles.subTitle}</StyledDescription>
          <StyledSubtitle id="batterySelect">בחירת טווח סוללה</StyledSubtitle>
        </Desktop>
        <Batteries
          noTitle={true}
          headlineId="batterySelect"
          setActiveBattery={setActiveBattery}
          batteries={batteryData}
        />
      </StyledHeaderWrap>
    </>
  )
}

export default BatteryDescription
