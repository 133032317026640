import { ButtonBase } from "@material-ui/core"
import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import RowWithTitle from "./RowWithTitle"

const StyledContainer = styled.div<{ $gap: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.$gap}px;
`
const StyledButtonWrap = styled(ButtonBase)<{
  $itemsPerRow: number | "auto"
  $gap: number
  $isshowroom?: string
}>`
  ${(p) => p.$isshowroom === "true" && `justify-content: flex-start;`}
  width: ${(props) =>
    props.$itemsPerRow === "auto"
      ? "auto!important"
      : `calc(100% / ${props.$itemsPerRow} - ${props.$gap}px * (${props.$itemsPerRow} - 1) / ${props.$itemsPerRow}) `};
`
const StyledLabel = styled.label<{ $isBattery: boolean }>`
  width: 100%;
  height: 44px;
  border: solid 1px transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.textGrey};
  font-size: 16px;
  direction: ${(p) => (p.$isBattery ? "ltr" : "rtl")};
  cursor: pointer;
  position: relative;
  ${(p) => !p.$isBattery && "box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);"}

  @media print {
    display: none;
    justify-content: flex-start;
    box-shadow: none;
    background-image: none !important;

    span {
      justify-content: flex-start;
    }
  }

  b {
    font-size: 1.5em;
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    line-height: 1;
  }

  ${(p) =>
    p.$isBattery
      ? `
    background-image:url('/battery-disabled.svg');
    background-size:contain;
    background-repeat:no-repeat;
    border:none;
    height: 0;
    padding-bottom: calc( 100% * 59 / 232);
    position: relative;

  `
      : `background-image:none !important;`}
`

const StyledRadio = styled.input`
  position: absolute;
  border: 0;
  margin: -1px;
  padding: 0;
  border-radius: 50%;
  width: 1px;
  height: 1px;
  opacity: 0;

  :checked + ${StyledLabel} {
    border-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    background-image: url("/battery-button.svg");
    @media print {
      display: block;
      border: none;
    }
  }

  :focus + ${StyledLabel} {
    border-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    background-image: url("/battery-button.svg");
    @media print {
      display: block;
      border: none;
    }
  }
`
const StyledKmNoBattery = styled.span`
  position: relative !important;
`

export type RadioItem = {
  value: string | number
  name: string
  secondaryName?: string
}

type Props = {
  id: string
  title?: string
  items: RadioItem[]
  onSelect: (value: string | number, index: number) => void
  isBattery?: boolean
  customButton?: (
    radioId: string,
    isSelected: boolean,
    item: RadioItem,
    index: number
  ) => React.ReactNode
  headlineComponent?: string
  itemsPerRow?: number | "auto"
  gap?: number
  selectedValue?: string
  isshowroom?: boolean
}

const SingleButtonSelection = ({
  id,
  title,
  items,
  onSelect,
  isBattery,
  customButton,
  headlineComponent,
  selectedValue,
  itemsPerRow = 2,
  gap = 10,
  isshowroom,
}: Props) => {
  const [selectedButton, setSelectedButton] = useState<string | number>(
    selectedValue || items[0].value
  )
  useEffect(() => {
    if (!items || !items.length || !selectedValue) {
      return
    }
    setSelectedButton(selectedValue || items[0].value)
  }, [items, selectedValue])

  const handleChange = (value: string | number, index: number) => {
    setSelectedButton(value)
    onSelect(value, index)
  }

  return (
    <RowWithTitle
      {...{ title, id, headlineComponent, isshowroom }}
      noPrint={!isBattery}
    >
      <StyledContainer role="radiogroup" aria-labelledby={id} $gap={gap}>
        {items.map((item, index) => {
          const radioId = `radio_${id}_${item.value}`
          return (
            <StyledButtonWrap
              $isshowroom={isshowroom && isshowroom?.toString()}
              tabIndex={-1}
              key={item.value}
              role="presentation"
              focusRipple
              $itemsPerRow={isshowroom ? "auto" : itemsPerRow}
              $gap={gap}
            >
              <StyledRadio
                aria-label="כפתור רדיו"
                type="radio"
                name={id}
                value={item.value}
                id={radioId}
                checked={item.value === selectedButton}
                onChange={() => {
                  handleChange(item.value, index)
                  setSelectedButton(item.value)
                }}
              />
              {customButton ? (
                customButton(
                  radioId,
                  item.value === selectedButton,
                  item,
                  index
                )
              ) : (
                <StyledLabel $isBattery={isBattery} htmlFor={radioId}>
                  <span>
                    {item.secondaryName && (
                      <b>{item.secondaryName.toUpperCase()}&nbsp;</b>
                    )}
                    {isBattery && <span> {item.name?.toUpperCase()}</span>}

                    <StyledKmNoBattery>
                      {item.name?.toUpperCase()}
                    </StyledKmNoBattery>
                  </span>
                </StyledLabel>
              )}
            </StyledButtonWrap>
          )
        })}
      </StyledContainer>
    </RowWithTitle>
  )
}

export default memo(SingleButtonSelection)
